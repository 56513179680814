import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/back-office.module.scss'
import BackOfficeProductPresentation from '../pagesSections/back-office/ProductPresentation'
import Advantages from '../components/_V2/Advantages'

import AdditionalServices from '../pagesSections/index/AdditionalServices'
import CtaForm from '../pagesSections/index/CtaForm'
import BusinessTypes from '../components/_V2/BusinessTypes'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'
import Presentation from '../pagesSections/back-office/ProductPresentation/assets/back-office-presentation.png'
import PresentationBy from '../pagesSections/back-office/ProductPresentation/assets/back-office-presentation-by.png'
import PresentationKz from '../pagesSections/back-office/ProductPresentation/assets/back-office-presentation-kz.png'

import { getAdvantagesSlides } from '../pages-data/_V2/back-office/advantages'
import { externalLinks } from '../pages-data/_V2/common/links'

import { businessTypes } from '../pages-data/_V2/common/business-types-compact'
import FinanceFeatures from '../pagesSections/back-office/FinanceFeatures'
import AccountingFeatures from '../pagesSections/back-office/AccountingFeatures'
import AnalyticsFeatures from '../pagesSections/back-office/AnalyticsFeatures'
import CRMFeatures from '../pagesSections/back-office/CRMFeatures'
import StockFeatures from '../pagesSections/back-office/StockFeatures'
import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function BackOfficePage() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'Программа управления и учета в кафе и ресторанах — бэк-офис Quick Resto',
    'ru-KZ': 'Программа управления и учета в кафе и ресторанах — бэк-офис Quick Resto Казахстан',
    'kz-KZ': 'Программа управления и учета в кафе и ресторанах — бэк-офис Quick Resto Казахстан',
    'ru-BY': 'Программа управления и учета в кафе и ресторанах — бэк-офис Quick Resto | Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}back-office/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}back-office/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}back-office/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}back-office/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Программа складского учета для кафе и ресторанов → Аналитика продаж в реальном времени → Управление меню → Контроль персонала и нежелательных операций',
    'ru-KZ': 'Программа складского учета для кафе и ресторанов → Аналитика продаж в реальном времени → Управление меню → Контроль персонала и нежелательных операций. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Программа складского учета для кафе и ресторанов → Аналитика продаж в реальном времени → Управление меню → Контроль персонала и нежелательных операций. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Программа складского учета для кафе и ресторанов → Аналитика продаж в реальном времени → Управление меню → Контроль персонала и нежелательных операций. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-BY': `${externalLinks.quickrestoBY.href}${PresentationBy}`,
    "ru-KZ": `${externalLinks.quickrestoKZ.href}${PresentationKz}`,
    default: `${externalLinks.quickrestoRU.href}${Presentation}`
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    // {
    //   name: 'keywords',
    //   content: 'программа, лояльность, гости, мобильное приложение, предзаказ, пуш-уведомление, автоматизация, кафе, ресторан',
    // },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale] || ogImage.default
    },
  ]

  return (
    <DefaultLayout
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={pageStyles.pageWrapper}
      linkCanonical={currentCanonicalLink}
      footerClassName={pageStyles.pageSection}
    >
      <BackOfficeProductPresentation />

      <Advantages
        items={getAdvantagesSlides(localizationContext.locale)}
        className={classNames(
				  styles.backOffice__advantages,
				  pageStyles.pageSection,
        )}
      />

      <FinanceFeatures className={pageStyles.pageSection} />

      <StockFeatures className={pageStyles.pageSection} />

      <AccountingFeatures className={pageStyles.pageSection} />

      <CRMFeatures className={pageStyles.pageSection} />

      <AnalyticsFeatures className={pageStyles.pageSection} />

      <AdditionalServices className={pageStyles.pageSection} />

      <CtaForm isFry={false} className={pageStyles.pageSection} />

      <BusinessTypes key={localizationContext.locale} data={businessTypes(localizationContext.locale)} className={pageStyles.pageSection} />

      <KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
    </DefaultLayout>
  )
}
