import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return {
			pageName: "Бэк-офис",
			desktopTitle: <>Настроен на&nbsp;прибыль</>,
			subTitle: (
				<p className={styles.description}>
					Составление техкарт, расчет фудкоста, аналитика, управление персоналом
					и&nbsp;продажами,&nbsp;&mdash; построй целостную систему с&nbsp;Quick
					Resto.
				</p>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					alt="программа учета для ресторана"
					src="./assets/back-office-presentation-kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Бэк-офис",
			desktopTitle: <>Пайда алуға теңшелген</>,
			subTitle: (
				<p className={styles.description}>
					Техкарталарды жасау, фудкостты есептеу, аналитика, персоналды және сатуды басқару - Quick Resto-мен тұтас жүйені құрастырыңыз.
				</p>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					alt="программа учета для ресторана"
					src="./assets/back-office-presentation-kz.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	if (locale === 'ru-BY') {
		return {
			pageName: "Бэк-офис",
			desktopTitle: <>Настроен на&nbsp;прибыль</>,
			subTitle: (
				<p className={styles.description}>
					Составление техкарт, расчет фудкоста, аналитика, управление персоналом
					и&nbsp;продажами,&nbsp;&mdash; построй целостную систему с&nbsp;Quick
					Resto.
				</p>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<StaticImage
					className={styles.image}
					alt="программа учета для ресторана"
					src="./assets/back-office-presentation-by.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		}
	}

	return {
		pageName: "Бэк-офис",
		desktopTitle: <>Настроен на&nbsp;прибыль</>,
		subTitle: (
			<p className={styles.description}>
				Составление техкарт, расчет фудкоста, аналитика, управление персоналом
				и&nbsp;продажами,&nbsp;&mdash; построй целостную систему с&nbsp;Quick
				Resto.
			</p>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<StaticImage
				className={styles.image}
				alt="программа учета для ресторана"
				src="./assets/back-office-presentation.png"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	}
}

export default {
	pageName: "Бэк-офис",
	desktopTitle: <>Настроен на&nbsp;прибыль</>,
	subTitle: (
		<p className={styles.description}>
			Составление техкарт, расчет фудкоста, аналитика, управление персоналом
			и&nbsp;продажами,&nbsp;&mdash; построй целостную систему с&nbsp;Quick
			Resto.
		</p>
	),
	cta: pagesLinks.registration.text,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
	ctaLink: pagesLinks.registration,
	figure: (
		<StaticImage
			className={styles.image}
			alt="программа учета для ресторана"
			src="./assets/back-office-presentation.png"
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
}
